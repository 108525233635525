import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import GlobalStyles from 'GlobalStyle.styles';
import { autoLogin } from 'store/user/userSlice';
import useInterceptors from 'utils/hooks/useInterceptors';
import { STRIPE_PUBLISHABLE_KEY } from 'utils/constants/stripe';

import Header from 'organisms/Header/Header';
import Footer from 'organisms/Footer/Footer';
import ProtectedRoute from 'molecules/ProtectedRoute';
/* Pages */
import AuthPage from 'pages/AuthPage/AuthPage';
import InvoicesPage from 'pages/InvoicesPage/InvoicesPage';
import InvoiceDetailsPage from 'pages/InvoiceDetailsPage/InvoiceDetailsPage';
import MobileInvoicePaymentPage from 'pages/InvoicePaymentPage/MobileInvoicePaymentPage';
import InvoicePdfPage from 'pages/InvoicePdfPage/InvoicePdfPage';
import AccountPage from 'pages/AccountPage/AccountPage';
import CloudOpsReportPage from 'pages/CloudOpsReport/CloudOpsReportPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import PaymentPage from 'pages/PaymentPage/PaymentPage';
import SuperAdminDashboardPage from 'pages/SuperAdminDashboardPage/SuperAdminDashboardPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import CompaniesPage from 'pages/Companies/CompaniesPage';
import ContactsPage from 'pages/ContactsPage/ContactsPage';
import CompanyPage from 'pages/CompanyPage/CompanyPage';
import LogsPage from 'pages/LogsPage';
import Redirect from 'pages/Redirect';
import ReportsPage from 'pages/Reports';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { autoSetCurrentCompany } from 'store/common/commonSlice';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const App = () => {
  const interceptorsCompleted = useInterceptors();
  const dispatch = useBaoDispatch();
  const { value: user } = useBaoSelector((state) => state.user);

  const userType =
    user.userType || JSON.parse(localStorage.getItem('user') || '{}').userType;
  const isAnyAdmin = userType === SUPER_ADMIN || userType === ADMIN;

  useEffect(() => {
    dispatch(autoLogin());
  }, [dispatch]);

  useEffect(() => {
    if (!isAnyAdmin) {
      dispatch(autoSetCurrentCompany(user));
    }
  }, [user]);

  return (
    <BrowserRouter>
      <GlobalStyles />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Elements stripe={stripePromise}>
          {interceptorsCompleted && (
            <>
              {userType && !user?.isTemporaryPassword && <Header />}

              <Routes>
                {/* Not_Protected */}
                <Route
                  path="create-password"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="create-password" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="request-account"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="request" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="signin"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="signin" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="forgot-password"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="forgot" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="verification"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="verification" />
                    </ProtectedRoute>
                  }
                />

                {/* Common */}
                <Route
                  path="temporary-password"
                  element={
                    <ProtectedRoute>
                      <AuthPage mode="temporary-password" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="invoices"
                  element={
                    <ProtectedRoute>
                      <InvoicesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="invoice-details/:invoiceId"
                  element={
                    <ProtectedRoute>
                      <InvoiceDetailsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="mobile-payment/:invoiceId"
                  element={
                    <ProtectedRoute>
                      <MobileInvoicePaymentPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="invoice/:invoiceQbID"
                  element={
                    <ProtectedRoute>
                      <InvoicePdfPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="account"
                  element={
                    <ProtectedRoute>
                      <AccountPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="redirect"
                  element={
                    <ProtectedRoute>
                      <Redirect />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="coreports"
                  element={
                    <ProtectedRoute>
                      {userType === 'company_admin' ? (
                        <CloudOpsReportPage />
                      ) : (
                        <ReportsPage />
                      )}
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      {isAnyAdmin ? (
                        <SuperAdminDashboardPage />
                      ) : (
                        <DashboardPage />
                      )}
                    </ProtectedRoute>
                  }
                />

                {/* Company_Admin */}
                <Route
                  path="payment"
                  element={
                    <ProtectedRoute>
                      <PaymentPage />
                    </ProtectedRoute>
                  }
                />

                {/* Super_Admin */}
                <Route
                  path="logs"
                  element={
                    <ProtectedRoute>
                      <LogsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ProtectedRoute>
                      <SettingsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="companies"
                  element={
                    <ProtectedRoute>
                      <CompaniesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="contacts"
                  element={
                    <ProtectedRoute>
                      <ContactsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="company/:hsId"
                  element={
                    <ProtectedRoute>
                      <CompanyPage />
                    </ProtectedRoute>
                  }
                />

                <Route path="*" element={<Navigate to="dashboard" />} />
              </Routes>

              {userType && !user?.isTemporaryPassword && <Footer />}
            </>
          )}
        </Elements>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
